import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Image from "mui-image";
import InputAdornment from "@mui/material/InputAdornment";
import Person from "@mui/icons-material/Person";
import Lock from "@mui/icons-material/Lock";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import * as ActionTypes from "../../redux/actions";
import {
  registerNewAccount,
  loginGoogle,
  loginFacebook,
} from "../../controller/authController";
import IconButton from "@mui/material/IconButton";
import { toast } from "react-toastify";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import FacebookLogin from "@greatsumini/react-facebook-login";

const Create = ({ onUserLogin }) => {
  const navigate = useNavigate();
  const [t] = useTranslation();

  const doGoogle = useGoogleLogin({
    onSuccess: async (res) => {
      const { data } = await axios(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: {
            Authorization: `Bearer ${res?.access_token}`,
          },
        }
      );
      apiLoginGoogle(data.email, res.access_token);
    },
  });

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.keyCode === 13) {
        sendForm();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const createAccount = async (username, password) => {
    try {
      const res = await registerNewAccount(username, password);
      if (res.data.success === true) {
        toast.success(t("alert.login"));
        onUserLogin(res.data.data);
        navigate("/");
      } else {
        toast.error(t(res.data.statusText));
      }
    } catch (error) {
      
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          "Failed Login"
      );
    }
  };
  const apiLoginGoogle = async (email, token) => {
    try {
      const res = await loginGoogle(token, email);
      if (res.data.success === true) {
        toast.success(t("alert.login"));
        onUserLogin(res.data.data);
        navigate("/");
      } else {
        toast.error(t(res.data.statusText));
      }
    } catch (error) {
      
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          "Failed Login"
      );
    }
  };
  const apiLoginFacebook = async (email) => {
    try {
      const res = await loginFacebook(email);
      if (res.data.success === true) {
        toast.success(t("alert.login"));
        onUserLogin(res.data.data);
        navigate("/");
      } else {
        toast.error(t(res.data.statusText));
      }
    } catch (error) {
      
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          "Failed Login"
      );
    }
  };

  const printFormError = (formik, key) => {
    if (formik.touched[key] && formik.errors[key]) {
      return <div>{formik.errors[key]}</div>;
    }
    return null;
  };

  const formik = useFormik({
    initialValues: {
      user: "",
      password: "",
      confirmpassword: "",
    },
    validationSchema: Yup.object({
      user: Yup.string().email().required("Required"),
      password: Yup.string()
        .min(10, "Minimum 10 characters")
        .max(25, "Maximum 25 characters")
        .required("Required"),
    }),
    onSubmit: (values) => {
      if (values.confirmpassword === "") {
        createAccount(values.user, values.password);
      }
    },
  });

  const sendForm = () => {
    formik.submitForm();
  };

  return (
    <Container sx={{ backgroundColor: "secondary.main" }} maxWidth={false}>
      <Grid
        height="100vh"
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={6} sm={6} lg={3}>
          <Image
            src="/img/logo-menu.png"
            sx={{ mb: "10px", width: "300px !important" }}
          />
          <Card variant="outlined">
            <CardContent sx={{ textAlign: "center", mt: 3 }}>
              <Typography variant="h6" textAlign="center" mb="20px">
                Introduce the account data
              </Typography>
              <TextField
                {...formik.getFieldProps("user")}
                sx={{ width: "90%", mb: "20px" }}
                id="user"
                placeholder="input mail..."
                error={printFormError(formik, "user") !== null ? true : false}
                helperText={printFormError(formik, "user")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Person />
                    </InputAdornment>
                  ),
                }}
                variant="filled"
              />
              <TextField
                {...formik.getFieldProps("password")}
                sx={{ width: "90%", mb: "10px" }}
                placeholder="input password..."
                id="password"
                type="password"
                error={
                  printFormError(formik, "password") !== null ? true : false
                }
                helperText={printFormError(formik, "password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Lock />
                    </InputAdornment>
                  ),
                }}
                variant="filled"
              />
              <TextField
                {...formik.getFieldProps("confirmpassword")}
                sx={{ width: "90%", mb: "10px", display: "none" }}
                placeholder="input password..."
                id="confirmpassword"
                type="password"
                variant="filled"
              />
              <CardActions
                sx={{ justifyContent: "center", mb: "15px", mt: "10px" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => sendForm()}
                >
                  Create an account
                </Button>
              </CardActions>
              <Typography variant="h7" textAlign="center">
                Or continue with:
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                pt={1}
                justifyContent="center"
                spacing={1}
              >
                <IconButton onClick={doGoogle} color="primary" size="large">
                  <GoogleIcon />
                </IconButton>
                <FacebookLogin
                  appId="922218297938835"
                  onSuccess={(response) => {
                    apiLoginFacebook(response.accessToken);
                  }}
                  render={({ onClick }) => (
                    <IconButton onClick={onClick} color="primary" size="large">
                      <FacebookIcon />
                    </IconButton>
                  )}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                pt={1}
                justifyContent="center"
              >
                <Box sx={{ width: "50%" }}>
                  <Link to="/account/forgot">
                    <Typography variant="h7" textAlign="center">
                      Can't log in?
                    </Typography>
                  </Link>
                </Box>

                <Box sx={{ width: "50%" }}>
                  <Link to="/account/login">
                    <Typography variant="h7" textAlign="center">
                      Already have an account?
                    </Typography>
                  </Link>
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onUserLogin: (token) => dispatch({ type: ActionTypes.LOGIN_USER, token }),
});

export default connect(null, mapDispatchToProps)(Create);
