import axios from './axios';

export const checkValid = async function () {
  return await axios.post("/v1/auth/check", {
    nothing: "nothing"
  },{ withCredentials: true,credentials: 'include' });
};

export const logoutUser = async function () {
  return await axios.post("/api/auth/logout", {
    nothing: "nothing"
  },{ withCredentials: true });
};

export const changePassword = async function (username, password) {
  return await axios.post("/api/auth/password", {
    username: username,
    password: password,
  });
};

export const registerNewAccount = async function (email, password) {
  return await axios.post('/v1/auth/register', {
    email: email,
    password: password,
  });
};

export const loginGoogle = async function (token,email) {
  return await axios.post('/v1/auth/loginGoogle', {
    token: token,
    email: email
  },{ withCredentials: true,credentials: 'include' });
};

export const loginFacebook = async function (token) {
  return await axios.post('/v1/auth/loginFacebook', {
    token: token,
  },{ withCredentials: true,credentials: 'include' });
};

export const loginUser = async function (email,password) {
  return await axios.post('/v1/auth/login', {
    email: email,
    password: password,
  },{ withCredentials: true,credentials: 'include' });
};

export const forgotPassword = async function (email) {
  return await axios.post('/v1/auth/recoveryPassword', {
    email: email
  });
};