import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { connect } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import Container from "@mui/material/Container";
import * as ActionTypes from "../../../redux/actions";
import { catchError } from "../../../lib/functions";
import {
  newPublishedSeries,
  updatePublishedSeries,
} from "../../../controller/publishingController";
import {
  getAllPublishedAuthors,
  getAllGenres,
} from "../../../controller/publishingController";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

const NewSeries = ({
  openNewSeries,
  openNewSeriesData,
  closeNewSeries,
  setRefresh,
  isEditingSeries,
  userLogin,
}) => {
  const [t] = useTranslation();

  const drawerWidth = 600;
  const [open, setOpen] = useState(false);
  const [authors, setAuthors] = useState([]);
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [selectedAuthor2, setSelectedAuthor2] = useState(null);
  const [selectedAuthor3, setSelectedAuthor3] = useState(null);
  const [genres, setGenres] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [showUnder, setShowUnder] = useState([]);

  const changeAuthor = (e) => {
    setSelectedAuthor(e.target.value);
  };
  const changeAuthor2 = (e) => {
    setSelectedAuthor2(e.target.value);
  };
  const changeAuthor3 = (e) => {
    setSelectedAuthor3(e.target.value);
  };
  const changeShowUnder = (e) => {
    setShowUnder(e.target.value);
  };
  const changeGenre = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedGenres(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 7 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      authorID: "",
      authorID2: "",
      authorID3: "",
      genre: "",
      showUnder: ""
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      values.authorID = selectedAuthor;
      values.authorID2 = selectedAuthor2;
      values.authorID3 = selectedAuthor3;
      values.showUnder = showUnder;
      values.genre = selectedGenres;
      if (isEditingSeries === false) {
        callUpdatePublishedSeries(values, openNewSeriesData.id);
      } else {
        callNewPublishedSeries(values);
      }
    },
  });

  const sendForm = () => {
    formik.submitForm();
  };

  const callUpdatePublishedSeries = async (values, id) => {
    try {
      let res = await updatePublishedSeries(values, id, userLogin);
      if (res.data.success === true) {
        toast.success("Serie Updated", { theme: "colored" });
        closeModal();
      } else {
        throw new Error();
      }
    } catch (error) {
      catchError("Failed to create new group");
    }
  };

  const callGetGenres = async () => {
    try {
      let res = await getAllGenres(userLogin);
      if (res.data.success === true) {
        setGenres(res.data.data);
      } else {
        throw new Error();
      }
    } catch (error) {
      catchError("Failed to get all genres");
    }
  };

  const callNewPublishedSeries = async (values) => {
    try {
      let res = await newPublishedSeries(values, userLogin);
      if (res.data.success === true) {
        toast.success("Serie Created", { theme: "colored" });
        closeModal();
      } else {
        throw new Error();
      }
    } catch (error) {
      catchError("Failed to create new group");
    }
  };
  const callPublishedAuthors = async () => {
    try {
      let res = await getAllPublishedAuthors(userLogin);
      if (res.data.success === true) {
        setAuthors(res.data.data);
      } else {
        throw new Error();
      }
    } catch (error) {
      catchError(error, "Failed to load all published authors");
    }
  };

  useEffect(() => {
    if (openNewSeries === "show") {
      callGetGenres();
      callPublishedAuthors();
      if (openNewSeriesData !== null && openNewSeriesData !== undefined) {
        let transData = {
          name: openNewSeriesData.name,
          authorID: openNewSeriesData.authorID,
          authorID2: openNewSeriesData.authorID2,
          authorID3: openNewSeriesData.authorID3,
          showUnder: openNewSeriesData.showUnder
        };
        setSelectedAuthor(openNewSeriesData.authorID);
        setSelectedAuthor2(openNewSeriesData.authorID2);
        setSelectedAuthor3(openNewSeriesData.authorID3);
        setSelectedGenres(openNewSeriesData.genre);
        setShowUnder(openNewSeriesData.showUnder);
        formik.resetForm({ values: transData });
      }

      setOpen(true);
    }
  }, [openNewSeries]);

  const closeModal = () => {
    closeNewSeries();
    setOpen(false);
    formik.resetForm({
      initialValues: {
        name: "",
      },
      values: {
        name: "",
      },
    });
    setSelectedAuthor(null);
    setSelectedAuthor2(null);
    setSelectedAuthor3(null);
    setShowUnder(null);
    setSelectedGenres([]);
    setRefresh();
  };

  return (
    <Drawer
      PaperProps={{
        sx: {
          backgroundColor: "secondary.main",
          color: "black",
        },
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="temporary"
      anchor="right"
      open={open}
    >
      <Toolbar>
        <Typography variant="h5">New Series</Typography>
      </Toolbar>
      <Divider />
      <Container>
        <TextField
          sx={{ mt: 3, width: "100%" }}
          id="outlined-required"
          label="Name"
          placeholder="Name"
          {...formik.getFieldProps("name")}
        />
        <FormControl fullWidth sx={{ width: "100%", mt: 2 }}>
          <InputLabel id="inputAuthor">Made by Author</InputLabel>
          <Select
            labelId="inputAuthor"
            label="Made by Author"
            id="inputAuthor"
            value={selectedAuthor}
            onChange={(e) => changeAuthor(e)}
          >
            {authors.map((data, key) => (
              <MenuItem key={key} name={data.name} value={data.id}>
                {data.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ width: "100%", mt: 2 }}>
          <InputLabel id="inputAuthor2">Second Author</InputLabel>
          <Select
            labelId="inputAuthor2"
            label="Second Author"
            id="inputAuthor2"
            value={selectedAuthor2}
            onChange={(e) => changeAuthor2(e)}
          >
            {authors.map((data, key) => (
              <MenuItem key={key} name={data.name} value={data.id}>
                {data.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ width: "100%", mt: 2 }}>
          <InputLabel id="inputAuthor3">Third Author</InputLabel>
          <Select
            labelId="inputAuthor3"
            label="Third Author"
            id="inputAuthor3"
            value={selectedAuthor3}
            onChange={(e) => changeAuthor3(e)}
          >
            {authors.map((data, key) => (
              <MenuItem key={key} name={data.name} value={data.id}>
                {data.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ width: "100%", mt: 2 }}>
          <InputLabel id="inputGenre2">Show Under</InputLabel>
          <Select
            labelId="inputGenre2"
            label="Show Under"
            id="inputGenre2"
            value={showUnder}
            onChange={(e) => changeShowUnder(e)}
          >
            <MenuItem key={"Fantasy"} name={"Fantasy"} value={"Fantasy"}>
              <ListItemText primary={"Fantasy"} />
            </MenuItem>
            <MenuItem
              key={"GameLit / LitRPG"}
              name={"GameLit / LitRPG"}
              value={"GameLit / LitRPG"}
            >
              <ListItemText primary={"GameLit / LitRPG"} />
            </MenuItem>
            <MenuItem
              key={"Eastern Fantasy"}
              name={"Eastern Fantasy"}
              value={"Eastern Fantasy"}
            >
              <ListItemText primary={"Eastern Fantasy"} />
            </MenuItem>
            <MenuItem
              key={"Light Novels"}
              name={"Light Novels"}
              value={"Light Novels"}
            >
              <ListItemText primary={"Light Novels"} />
            </MenuItem>
            <MenuItem key={"Sci-Fi"} name={"Sci-Fi"} value={"Sci-Fi"}>
              <ListItemText primary={"Sci-Fi"} />
            </MenuItem>
            <MenuItem key={"Other"} name={"Other"} value={"Other"}>
              <ListItemText primary={"Other"} />
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ width: "100%", mt: 2 }}>
          <InputLabel id="inputGenre">Genre</InputLabel>
          <Select
            labelId="inputGenre"
            label="Genre"
            id="inputGenre"
            multiple
            value={selectedGenres}
            onChange={(e) => changeGenre(e)}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {genres.map((data, key) => (
              <MenuItem key={key} name={data} value={data}>
                <Checkbox checked={selectedGenres.indexOf(data) > -1} />
                <ListItemText primary={data} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Container>

      <Box
        sx={{
          backgroundColor: "cMoon.main",
          position: "fixed",
          bottom: 0,
          width: "600px",
          p: 1,
        }}
      >
        <Stack direction="row" justifyContent="end" spacing={2}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => closeModal()}
          >
            {t("general.close")}
          </Button>
          <Button variant="contained" onClick={() => sendForm()}>
            {t("general.update")}
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  openNewSeries: state.publishing.openSeries,
  openNewSeriesData: state.publishing.data,
  isEditingSeries: state.publishing.isEditing,
  userLogin: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  closeNewSeries: () => dispatch({ type: ActionTypes.CLOSE_NEW_SERIES }),
  setRefresh: () => dispatch({ type: ActionTypes.REFRESH }),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewSeries);
