import React, {useEffect,useState} from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import { getPersonalInfo } from "../controller/profileController";
import { toast } from "react-toastify";

const PersonalInfo = () => {
  const [t] = useTranslation();
  const [data,setData] = useState(null);

  const getProfile = async () => {
    try {
      const res = await getPersonalInfo();
      
      if (res.data.success === true) {
        
       
      } else {
        toast.error(t(res.data.statusText), { theme: "colored" });
      }
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          "Failed"
      );
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <Grid container mt={2} spacing={2}>
        hola personal
     </Grid>
    </>
  );
};

export default connect(null, null)(PersonalInfo);