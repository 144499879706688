import * as ActionTypes from "../actions";
import {isMobile} from 'react-device-detect';
//Handling sensor state

const initialState = {
  showMenu: (isMobile === true ? "hide" : "show" ),
};

const reducer = (state = initialState, action) => {
  if (action.type === ActionTypes.SHOW_MENU) {
    
    return {
      ...state,
      showMenu: "show"
    };
  }
  if (action.type === ActionTypes.HIDE_MENU) {
    
    return {
      ...state,
      showMenu: "hide"
    };
  }


  return { ...state };
};

export default reducer;
