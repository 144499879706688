import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { connect } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import Container from "@mui/material/Container";
import * as ActionTypes from "../../../redux/actions";
import { catchError } from "../../../lib/functions";
import { styled } from "@mui/material/styles";
import {
  newPublishedAuthor,
  updatePublishedAuthor,
} from "../../../controller/publishingController";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
const NewAuthor = ({
  openAuthor,
  openAuthorData,
  closeNewGroup,
  setRefresh,
  isEditing,
  userLogin
}) => {
  const [t] = useTranslation();

  const drawerWidth = 600;
  const [open, setOpen] = useState(false);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  function encodeBase64Async(fileContent) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result.split(',')[1];
        resolve(base64Data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(new Blob([fileContent]));
    });
  }

  const handleFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
  
    reader.onload = async function (event) {
      try {
        const base64Data = event.target.result.split(',')[1]; // Extract base64 data from data URL
        // Now you can use base64Data as needed, such as saving it to formik.values.picture
        formik.values.picture = base64Data;
      } catch (error) {
        console.error('Error converting file to base64:', error);
      }
    };
  
    reader.readAsDataURL(file); // Read the file as data URL asynchronously
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      linkDiscord: "",
      linkAmazon: "",
      linkPatreon: "",
      linkGoodReads: "",
      picture: ""
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      if (isEditing === false) {
        callUpdatePublishedAuthor(values, openAuthorData.id);
      } else {
        callNewPublishedAuthor(values);
      }
    },
  });

  const sendForm = () => {
    formik.submitForm();
  };

  const callUpdatePublishedAuthor = async (values, id) => {
    try {
      let res = await updatePublishedAuthor(values, id,userLogin);
      if (res.data.success === true) {
        toast.success("Author updated.", { theme: "colored" });
        closeModal();
      } else {
        throw new Error();
      }
    } catch (error) {
      catchError("Failed to update author");
    }
  };

  const callNewPublishedAuthor = async (values) => {
    try {
      let res = await newPublishedAuthor(values,userLogin);
      if (res.data.success === true) {
        toast.success("Author created.", { theme: "colored" });
        closeModal();
      } else {
        throw new Error();
      }
    } catch (error) {
      catchError("Failed to create new author");
    }
  };

  useEffect(() => {
    if (openAuthor === "show") {
      if (openAuthorData !== null && isEditing===false) {
        formik.resetForm({ values: openAuthorData });
      }else{
        formik.resetForm({ values: "" });
      }
      setOpen(true);
    }
  }, [openAuthor]);

  const closeModal = () => {
    closeNewGroup();
    setOpen(false);
    setRefresh();
  };

  return (
    <Drawer
      PaperProps={{
        sx: {
          backgroundColor: "secondary.main",
          color: "black",
        },
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="temporary"
      anchor="right"
      open={open}
    >
      <Toolbar>
        <Typography variant="h5">Author Info</Typography>
      </Toolbar>
      <Divider />
      <Container>
        <TextField
          sx={{ mt: 3, width: "100%" }}
          id="outlined-required"
          label="Name"
          placeholder="Name"
          {...formik.getFieldProps("name")}
        />
        <TextField
          multiline
          sx={{ mt: 3, width: "100%" }}
          id="outlined-required"
          label="Description"
          placeholder="Description"
          {...formik.getFieldProps("description")}
        />
        <TextField
          sx={{ mt: 3, width: "100%" }}
          id="outlined-required"
          label="Discord Link"
          placeholder="Discord Link"
          {...formik.getFieldProps("linkDiscord")}
        />
        <TextField
          sx={{ mt: 3, width: "100%" }}
          id="outlined-required"
          label="Amazon Link"
          placeholder="Amazon Link"
          {...formik.getFieldProps("linkAmazon")}
        />
        <TextField
          sx={{ mt: 3, width: "100%" }}
          id="outlined-required"
          label="Patreon Link"
          placeholder="Patreon Link"
          {...formik.getFieldProps("linkPatreon")}
        />
        <TextField
          sx={{ mt: 3, width: "100%" }}
          id="outlined-required"
          label="GoodReads Link"
          placeholder="GoodReads Link"
          {...formik.getFieldProps("linkGoodReads")}
        />
        <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            Upload file
            <VisuallyHiddenInput id="custom-file-input" onChange={(e) => handleFile(e)} type="file" />
          </Button>
      </Container>

      <Box
        sx={{
          backgroundColor: "cMoon.main",
          position: "fixed",
          bottom: 0,
          width: "600px",
          p: 1,
        }}
      >
        <Stack direction="row" justifyContent="end" spacing={2}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => closeModal()}
          >
            {t("general.close")}
          </Button>
          <Button variant="contained" onClick={() => sendForm()}>
            {t("general.update")}
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  openAuthor: state.publishing.openAuthor,
  openAuthorData: state.publishing.data,
  isEditing: state.publishing.isEditing,
  userLogin: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  closeNewGroup: () => dispatch({ type: ActionTypes.CLOSE_NEW_AUTHOR }),
  setRefresh: () => dispatch({ type: ActionTypes.REFRESH }),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewAuthor);
