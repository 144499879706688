import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { connect } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import Container from "@mui/material/Container";
import * as ActionTypes from "../../../redux/actions";
import { catchError } from "../../../lib/functions";
import { newNews, updateNews } from "../../../controller/publishingController";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const NewNews = ({
  openNewNewsData,
  openNewNews,
  closeNewNews,
  setRefresh,
  isEditingNews,
  userLogin,
}) => {
  const [t] = useTranslation();

  const drawerWidth = 900;
  const [open, setOpen] = useState(false);
  const [editorHtml, setEditorHtml] = useState('');




  const formik = useFormik({
    initialValues: {
      title: "",
      date: "",
      picture: "",
      content: "",
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
        values.content = editorHtml;
      if (isEditingNews === false) {
        callUpdateNews(values, openNewNewsData.id);
      } else {
        callNewNews(values);
      }
    },
  });

  const sendForm = () => {
    formik.submitForm();
  };
  const callNewNews = async (values) => {
    try {
      let res = await newNews(values, userLogin);
      if (res.data.success === true) {
        toast.success("New News Created", { theme: "colored" });
        closeModal();
      } else {
        throw new Error();
      }
    } catch (error) {
      catchError("Failed to create new news");
    }
  };
  const callUpdateNews = async (values, id) => {
    try {
      let res = await updateNews(values, id, userLogin);
      if (res.data.success === true) {
        toast.success("News Updated", { theme: "colored" });
        closeModal();
      } else {
        throw new Error();
      }
    } catch (error) {
      catchError("Failed to create update news");
    }
  };

  useEffect(() => {
    
    if (openNewNews === "show") {
      if (openNewNewsData !== null) {
        let transData = {
          title: openNewNewsData.title,
          date: openNewNewsData.date,
          content: openNewNewsData.content,
        };
        setEditorHtml(openNewNewsData.content)
        formik.resetForm({ values: transData });
      }

      setOpen(true);
    }
  }, [openNewNews]);

  const closeModal = () => {
    closeNewNews();
    setOpen(false);
    setEditorHtml("")
    formik.resetForm({
      initialValues: {
        title: "",
        date: "",
        content: "",
      },
      values: {
        title: "",
        date: "",
        content: "",
      },
    });
    setRefresh();
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  const handleFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async function (event) {
      try {
        const base64Data = event.target.result.split(",")[1]; // Extract base64 data from data URL
        // Now you can use base64Data as needed, such as saving it to formik.values.picture
        formik.values.picture = base64Data;
      } catch (error) {
        console.error("Error converting file to base64:", error);
      }
    };

    reader.readAsDataURL(file); // Read the file as data URL asynchronously
  };

  const handleEditorChange = (value) => {
    setEditorHtml(value);
  };

  return (
    <Drawer
      PaperProps={{
        sx: {
          backgroundColor: "secondary.main",
          color: "black",
        },
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="temporary"
      anchor="right"
      open={open}
    >
      <Toolbar>
        <Typography variant="h5">New News</Typography>
      </Toolbar>
      <Divider />
      <Container>
        <TextField
          sx={{ mt: 3, width: "100%" }}
          id="outlined-required"
          label="Title"
          placeholder="Title"
          {...formik.getFieldProps("title")}
        />
        <Button
          sx={{ mt: 3, width: "100%", mb:3 }}
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
        >
          Upload Image
          <VisuallyHiddenInput
            id="custom-file-input"
            onChange={(e) => handleFile(e)}
            type="file"
          />
        </Button>
        <ReactQuill value={editorHtml} onChange={handleEditorChange} />
      </Container>

      <Box
        sx={{
          backgroundColor: "cMoon.main",
          position: "fixed",
          bottom: 0,
          width: "900px",
          p: 1,
        }}
      >
        <Stack direction="row" justifyContent="end" spacing={2}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => closeModal()}
          >
            {t("general.close")}
          </Button>
          <Button variant="contained" onClick={() => sendForm()}>
            {t("general.update")}
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  openNewNews: state.publishing.openNewNews,
  openNewNewsData: state.publishing.data,
  isEditingNews: state.publishing.isEditing,
  userLogin: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  closeNewNews: () => dispatch({ type: ActionTypes.CLOSE_NEW_NEWS }),
  setRefresh: () => dispatch({ type: ActionTypes.REFRESH }),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewNews);
