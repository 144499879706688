import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import * as ActionTypes from "../../redux/actions";
import { connect } from "react-redux";
import {
  getAllPublishedBooks,
  deletePublishedBook,
  featurePublished,
  getAllFeatured,
  getAllComingSoon
} from "../../controller/publishingController";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import { catchError } from "../../lib/functions";
import NewBook from "../drawer/publishing/NewBook";
import GradeIcon from "@mui/icons-material/Grade";
const DatagridBooks = ({ refresh, setNoRefresh, setRefresh, openDrawer,userLogin }) => {
  const [t] = useTranslation();
  const [rows, setRows] = useState([]);
  const [feature, setFeature] = useState([]);
  const [soon, setSoon] = useState([]);

  const ShowOptions = (data) => {
    const handleEdit = () => {
      openDrawer(data.data, false);
    };
    const handleFeatured = () => {
      waitFeatured(data.data, data.data.bookPosition);
    };
    const handleDelete = () => {
      waitConfirmation(data.data, data.data.bookPosition);
    };

    return (
      <>
        <IconButton onClick={handleFeatured} color="primary">
          <GradeIcon />
        </IconButton>
        <IconButton onClick={handleEdit} color="primary">
          <EditIcon />
        </IconButton>
        <IconButton onClick={handleDelete} color="primary">
          <DeleteIcon />
        </IconButton>
      </>
    );
  };
  const FindFeature = (data) => {
    let found = "No"
    feature.forEach(element => {
      if(element.name === data.data.name){
        
        found = "Yes"
      }
    });

    return found;

  }
  const FindFSoon = (data) => {
    let found = "No"
    soon.forEach(element => {
      if(element.name === data.data.name){
        
        found = "Yes"
      }
    });

    return found;

  }
  const columns = [
    {
      field: "name",
      headerName: t("general.name"),
      flex: 0.3,
      sortable: false,
      hideable: false,
    },
    {
      field: "seriesName",
      headerName: "Series Name",
      flex: 0.2,
      sortable: false,
      hideable: false,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 0.1,
      sortable: false,
      hideable: false,
    },
    {
      field: "featured",
      headerName: "Featured",
      flex: 0.1,
      sortable: false,
      hideable: false,
      renderCell: (params) => <FindFeature data={params.row} />
    },
    {
      field: "soon",
      headerName: "Coming Soon",
      flex: 0.1,
      sortable: false,
      hideable: false,
      renderCell: (params) => <FindFSoon data={params.row} />
    },
    {
      field: "actions",
      headerName: t("general.options"),
      flex: 0.1,
      sortable: false,
      hideable: false,
      renderCell: (params) => <ShowOptions data={params.row} />,
    },
  ];

  const parseData = (data) => {
    let newData = [];
    data.forEach((element) => {
      if (element.books !== undefined) {
        element.books.forEach((book, index) => {
          newData.push({
            name: book.name,
            seriesName: element.name,
            bookPosition: index,
            seriesID: element.id,
            num: book.num,
            description: book.description,
            linkAmazon: book.linkAmazon,
            linkAudio: book.linkAudio,
            slug:book.slug,
            type: "Book",
          });
        });
      }
      if (element.comics !== undefined) {
        element.comics.forEach((comic, index) => {
          newData.push({
            name: comic.name,
            seriesName: element.name,
            bookPosition: index,
            seriesID: element.id,
            num: comic.num,
            description: comic.description,
            linkAmazon: comic.linkAmazon,
            linkAudio: comic.linkAudio,
            type: "Comic",
          });
        });
      }
    });
    setRows(newData);
  };

  const callFeaturePublished = async (id, position) => {
    try {
      const waiting = toast.success(t("alert.wait"), { theme: "colored" });
      let res = await featurePublished(id, position,userLogin);
      if (res.data.success === true) {
        if (res.data.status === "0") {
          toast.update(waiting, {
            render: "This is already featured",
            type: "error",
          });
        } else {
          toast.update(waiting, {
            render: "Featured Correctly",
            type: "success",
          });
        }

        setRefresh();
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
      catchError(error, "Failed to callFeaturePublished");
    }
  };
  const callAllFeaturePublished = async () => {
    try {
      let res = await getAllFeatured(userLogin);
      if (res.data.success === true) {
        setFeature(res.data.data);
        callPublishedData();
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
      catchError(error, "Failed to callAllFeaturePublished");
    }
  };
  const callAllComingSoon = async () => {
    try {
      let res = await getAllComingSoon(userLogin);
      if (res.data.success === true) {
        setSoon(res.data.data);
        callPublishedData();
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
      catchError(error, "Failed to callAllComingSoon");
    }
  };
  const callPublishedData = async () => {
    try {
      let res = await getAllPublishedBooks(userLogin);
      if (res.data.success === true) {
        parseData(res.data.data);
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
      catchError(error, "Failed to callPublishedData");
    }
  };

  useEffect(() => {
    callAllFeaturePublished();
    callAllComingSoon();
  }, []);
  useEffect(() => {
    if (refresh === "yes") {
      callAllFeaturePublished()
      callAllComingSoon();
      setNoRefresh();
    }
  }, [refresh]);

  const waitFeatured = async (id, position) => {
    const accept = await Swal.fire({
      title: t("alert.alertTitleSure"),
      text: "You are going to set this book as featured",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#7ea0bd",
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      }
    });
    if (accept) {
      callFeaturePublished(id, position);
    }
  };
  const waitConfirmation = async (id, position) => {
    const accept = await Swal.fire({
      title: t("alert.alertTitleSure"),
      text: t("alert.deleteText"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#7ea0bd",
      cancelButtonText: "Cancel",
      confirmButtonText: t("alert.delete"),
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      }
    });
    if (accept) {
      callDeletePublishedData(id, position);
    }
  };
  const callDeletePublishedData = async (id, position) => {
    const waiting = toast.success(t("alert.wait"), { theme: "colored" });
    try {
      const res = await deletePublishedBook(id, position,userLogin);
      if (res.data.success === true) {
        toast.update(waiting, {
          render: "Delete Published Book",
          type: "success",
        });
        setRefresh();
      } else {
        throw new Error();
      }
    } catch (error) {
      catchError(error, "Failed to delete book");
    }
  };
  return (
    <>
      <Stack direction="row" spacing={2} mb={2} justifyContent="flex-end">
        <Button
          color="cMoon"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => openDrawer(null, true)}
        >
          {t("general.new")}
        </Button>
      </Stack>
      <DataGrid
        disableColumnFilter
        autoHeight={true}
        rows={rows}
        columns={columns}
        getRowId={(row) => row.name}
        sx={{
          ".MuiDataGrid-columnHeader": {
            backgroundColor: "cMoon.main",
            color: "white",
          },
          ".MuiDataGrid-footerContainer": {
            display: "show",
          },
          ".MuiDataGrid-cell": {
            backgroundColor: "white",
          },
        }}
        disableColumnMenu={true}
       
      />
      <NewBook />
    </>
  );
};

const mapStateToProps = (state) => ({
  refresh: state.publishing.refresh,
  userLogin: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  openDrawer: (data, edit) =>
    dispatch({ type: ActionTypes.OPEN_NEW_BOOK, data, edit }),
  setNoRefresh: () => dispatch({ type: ActionTypes.NO_REFRESH }),
  setRefresh: () => dispatch({ type: ActionTypes.REFRESH }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DatagridBooks);
