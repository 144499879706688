import React from "react";
import ReactDOM from "react-dom/client";
import App from "./client/App";
import "./client/locales/i18n"; //init handling languages
import { Provider } from "react-redux";
import store from "./client/redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const theme = createTheme({
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#1b3666 !important",
            borderRadius: "0.5rem",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          "&.MuiCard-root": {
            borderRadius: "15px",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      light: "#757ce8",
      main: "#1b3666",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#d9dadb",
      dark: "#ba000d",
      contrastText: "#000",
    },
    cMoon: {
      light: "#385672",
      main: "#385672",
      dark: "#385672",
      contrastText: "#fff",
    },
    cWhite: {
      light: "#fff",
      main: "#fff",
      dark: "#000",
      contrastText: "#000",
    },
    cRed: {
      light: "#ce3140",
      main: "#ce3140",
      dark: "#ce3140",
      contrastText: "#fff",
    },
    cGreen: {
      light: "#53b96a",
      main: "#53b96a",
      dark: "#53b96a",
      contrastText: "#fff",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <App />
    </ThemeProvider>
  </Provider>
);
