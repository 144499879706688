import React from "react";
import { useTranslation } from "react-i18next";

import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import project from '../../../../package.json';

const Footer = () => {
  const [t] = useTranslation();

  return (
    <Stack mt={1} mb={1} direction="row" pt={1} pb={1} justifyContent="space-between">
      <Typography variant="subtitle2">
        Copyright © 2019 - {new Date().getFullYear()}.{" "}
        <Link underline="none" href="https://moonquill.com">InkQuill LLC.</Link> {" "}
        All rights reserved
      </Typography>
      <Typography variant="subtitle2">Version {project.version}</Typography>
    </Stack>
  );
};

export default Footer;
