export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOAD_USER = "LOAD_USER";

//util
export const SHOW_MENU = "SHOW_MENU";
export const HIDE_MENU = "HIDE_MENU";
export const REFRESH = "REFRESH";
export const NO_REFRESH = "NO_REFRESH";

//Author Actions
export const OPEN_NEW_AUTHOR = "OPEN_NEW_AUTHOR";
export const CLOSE_NEW_AUTHOR = "CLOSE_NEW_AUTHOR";

//Series Actions
export const OPEN_NEW_SERIES = "OPEN_NEW_SERIES";
export const CLOSE_NEW_SERIES = "CLOSE_NEW_SERIES";

//Book Actions
export const OPEN_NEW_BOOK = "OPEN_NEW_BOOK";
export const CLOSE_NEW_BOOK = "CLOSE_NEW_BOOK";

//Book Actions
export const OPEN_NEW_COMIC = "OPEN_NEW_COMIC";
export const CLOSE_NEW_COMIC = "CLOSE_NEW_COMIC";

//News
export const CLOSE_NEW_NEWS = "CLOSE_NEW_NEWS";
export const OPEN_NEW_NEWS = "OPEN_NEW_NEWS";
//Security
export const SET_MOD = "SET_MOD";