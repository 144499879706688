import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as ActionTypes from "../../redux/actions";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from "@mui/icons-material/Settings";
import ListItemIcon from "@mui/material/ListItemIcon";
import LanguagesDropdown from "../select/LanguagesDropdown"
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import Popover from "@mui/material/Popover";
import Divider from "@mui/material/Divider";
import MenuBreadCrumbs from "../breadcrumbs/Menu";
import Link from "@mui/material/Link";
import { logoutUser } from "../../controller/authController";

import { isMobile } from "react-device-detect";
const Header = ({ showMenu, hideMenu, onLogOut }) => {
  const [t] = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [width, setWidth] = useState(isMobile === true ? 0 : 240);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutCall = async () => {
    try {
      const res = await logoutUser();
      if (res.data.success === true) {
        window.location.href = "/";
      }
    } catch (error) {
      console.log(error);
    }
  };

  const logout = () => {
    onLogOut();
    logoutCall();
  };

  const handleHideMenu = () => {
    if (width === 80) {
      showMenu();
      setWidth(240);
    }
    if (width === 0) {
      showMenu();
      setWidth(240);
    }
    if (width === 240) {
      hideMenu();
      if(isMobile){
        setWidth(0);
      }else{
        setWidth(80);
      }
      
    }
  };

  return (
    <AppBar
      color="cWhite"
      position="fixed"
      sx={{
        width: "calc(100% - " + width + "px)",
        ml: width + "px",
        boxShadow: 0,
      }}
    >
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={handleHideMenu}
        >
          <MenuIcon />
        </IconButton>

        <MenuBreadCrumbs />

        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
          sx={{marginLeft:1}}
        >
          <AccountCircle style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Paper sx={{ width: 200 }} elevation={3}>
            <MenuList>
              <Typography sx={{ ml: 2, mt: 1 }} variant="inherit">
                Hola, admin
              </Typography>

           
             <Divider sx={{ mb: 1, mt: 1 }} />
{/*
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <SettingsIcon fontSize="small" />
                </ListItemIcon>
                Cuenta
              </MenuItem>

              <Divider sx={{ mb: 1, mt: 1 }} />*/} 
              <Link href="#" underline="none" color="black" onClick={logout}>
                {" "}
                <Typography sx={{ ml: 2 }} variant="inherit">
                  Log Out
                </Typography>
              </Link>
            </MenuList>
          </Paper>
        </Popover>
      </Toolbar>
    </AppBar>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showMenu: () => dispatch({ type: ActionTypes.SHOW_MENU }),
  hideMenu: () => dispatch({ type: ActionTypes.HIDE_MENU }),
  onLogOut: () => dispatch({ type: ActionTypes.LOGOUT_USER }),
});

export default connect(null, mapDispatchToProps)(Header);
