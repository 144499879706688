import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth";
import utilReducer from "./reducers/util";
import publishingReducer from "./reducers/publishing";

const store = configureStore({
  reducer: {
    auth: authReducer,
    util: utilReducer,
    publishing: publishingReducer
  },
});

export default store;
