import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";

const PublicRoute = ({ children, isLoggedIn }) => {
  const isAuthenticated = isLoggedIn || localStorage.getItem("token");

  return isAuthenticated ? (
    <Navigate
      to={{
        pathname: "/",
      }}
    />
  ) : (
    children
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps, null)(PublicRoute);
