import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";

import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import SensorsIcon from "@mui/icons-material/Sensors";
import Image from "mui-image";
import ArticleIcon from "@mui/icons-material/Article";
import PersonIcon from '@mui/icons-material/Person';
import FolderIcon from '@mui/icons-material/Folder';
import BookIcon from '@mui/icons-material/Book';

const Menu = ({ stateMenu, isMOD }) => {
  const [t] = useTranslation();
  const location = useLocation();
  let crumbs = location.pathname.split("/");
  crumbs.splice(0, 1);

  const navigate = useNavigate();
  const [drawerWidth, setDrawerWidth] = useState(isMobile === true ? 0 : 240);
  const [display, setDisplay] = useState("unset");

  useEffect(() => {
    if (stateMenu === "show") {
      setDrawerWidth(240);
      setDisplay("unset");
    } else {
      if (isMobile) {
        setDrawerWidth(0);
      } else {
        setDrawerWidth(80);
      }
    }
    if (stateMenu === "hide") {
      setDisplay("none");
    }
  }, [stateMenu]);

  return (
    <Drawer
      PaperProps={{
        sx: {
          backgroundColor: "#385672",
          color: "white",
          border: 0,
          BorderRight: 0,
        },
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        boxShadow: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          borderRight: null,
          overflow: "hidden",
        },
      }}
      variant="permanent"
      anchor="left"
      open={false}
    >
      <Toolbar sx={{ pl: "24px !important" }}>
        <Image src="/img/logo-menu.png" height={32} />
      </Toolbar>
      <Divider />
      <List sx={{ pl: 1.5, pr: 1.5 }}>
        <ListItemButton
          selected={crumbs[0] === "" ? true : false}
          onClick={() => navigate("")}
        >
          <ListItemIcon>
            <HomeIcon color="cWhite" />
          </ListItemIcon>
          <ListItemText
            sx={{ display: display }}
            primary={t("routes.overview")}
          />
        </ListItemButton>

        <ListItemButton
          selected={crumbs[0] === "personal-info" ? true : false}
          onClick={() => navigate("/personal-info")}
        >
          <ListItemIcon>
            <SensorsIcon color="cWhite" />
          </ListItemIcon>
          <ListItemText
            sx={{ display: display }}
            primary={t("routes.personalInfo")}
          />
        </ListItemButton>

        {isMOD === "yes" ? (
          <>
            <ListItemText
              sx={{ marginTop: 2, marginLeft: 2 }}
              mt={2}
              primary="Publishing Site"
            />
            <ListItemButton
              selected={crumbs[0] === "published-news" ? true : false}
              onClick={() => navigate("/published-news")}
            >
              <ListItemIcon>
                <ArticleIcon color="cWhite" />
              </ListItemIcon>
              <ListItemText sx={{ display: display }} primary="News" />
            </ListItemButton>
            <ListItemButton
              selected={crumbs[0] === "published-authors" ? true : false}
              onClick={() => navigate("/published-authors")}
            >
              <ListItemIcon>
                <PersonIcon color="cWhite" />
              </ListItemIcon>
              <ListItemText sx={{ display: display }} primary="Authors" />
            </ListItemButton>
            <ListItemButton
              selected={crumbs[0] === "published-series" ? true : false}
              onClick={() => navigate("/published-series")}
            >
              <ListItemIcon>
                <FolderIcon color="cWhite" />
              </ListItemIcon>
              <ListItemText sx={{ display: display }} primary="Series" />
            </ListItemButton>
            <ListItemButton
              selected={crumbs[0] === "published-books" ? true : false}
              onClick={() => navigate("/published-books")}
            >
              <ListItemIcon>
                <BookIcon color="cWhite" />
              </ListItemIcon>
              <ListItemText
                sx={{ display: display }}
                primary="Books & Comics"
              />
            </ListItemButton>
          </>
        ) : null}
      </List>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  stateMenu: state.util.showMenu,
  isMOD: state.auth.isMOD,
});

export default connect(mapStateToProps, null)(Menu);
