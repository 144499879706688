import React from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2";
import DatagridBooks from "../../components/datagrid/DatagridBooks";
import { useTranslation } from "react-i18next";

const Books = () => {
  const [t] = useTranslation();
  return (
    <>
      <Grid container mt={2} spacing={2}>
        <Grid xs={12}>
          <DatagridBooks/>
        </Grid>
      </Grid>
    </>
  );
};

export default connect(null, null)(Books);