import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import * as ActionTypes from "../../redux/actions";
import { connect } from "react-redux";
import {
  getAllNews,deleteNews
} from "../../controller/publishingController";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import { catchError } from "../../lib/functions";
import NewNews from "../drawer/publishing/NewNews";

const DatagridSeries = ({
  refresh,
  setNoRefresh,
  setRefresh,
  openDrawer,
  userLogin
}) => {
  const [t] = useTranslation();
  const [rows, setRows] = useState([]);

  const ShowOptions = (data) => {
    const handleEdit = () => {
        openDrawer(data.data, false);
    };
    const handleDelete = () => {
      waitDeleteGroupConfirmation(data.data.id);
    };
    return (
      <>
        <IconButton onClick={handleEdit} color="primary">
          <EditIcon />
        </IconButton>
        <IconButton onClick={handleDelete} color="primary">
          <DeleteIcon />
        </IconButton>
      </>
    );
  };
  const columns = [
    {
      field: "title",
      headerName: "Title",
      flex: 0.9,
      sortable: false,
      hideable: false,
    },
    {
      field: "actions",
      headerName: t("general.options"),
      flex: 0.1,
      sortable: false,
      hideable: false,
      renderCell: (params) => <ShowOptions data={params.row} />,
    },
  ];

  const callAllNews = async () => {
    try {
      let res = await getAllNews(userLogin);
      if (res.data.success === true) {
        setRows(res.data.data);
      } else {
        throw new Error();
      }
    } catch (error) {
      catchError(error, "Failed to load news");
    }
  };

  useEffect(() => {
    callAllNews();
  }, []);
  useEffect(() => {
    if (refresh === "yes") {
        callAllNews();
      setNoRefresh();
    }
  }, [refresh]);
  const waitDeleteGroupConfirmation = async (id) => {
    const accept = await Swal.fire({
      title: t("alert.alertTitleSure"),
      text: t("alert.deleteText"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#7ea0bd",
      cancelButtonText: "Cancel",
      confirmButtonText: t("alert.delete"),
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      }
    });
    if (accept) {
        callDeleteNews(id);
    }
  };
  const callDeleteNews = async (id) => {
    const waiting = toast.success(t("alert.wait"), { theme: "colored" });
    try {
      const res = await deleteNews(id,userLogin);
      if (res.data.success === true) {
        toast.update(waiting, {
          render: "Deleted News",
          type: "success",
        });
        setRefresh();
      } else {
        throw new Error();
      }
    } catch (error) {
      catchError(error, "Failed to delete news");
    }
  };
  return (
    <>
      <Stack direction="row" spacing={2} mb={2} justifyContent="flex-end">
        <Button
          color="cMoon"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => openDrawer(null, true)}
        >
          {t("general.new")}
        </Button>
      </Stack>
      <DataGrid
        disableColumnFilter
        autoHeight={true}
        rows={rows}
        columns={columns}
        getRowId={(row) => row.id}
        sx={{
          ".MuiDataGrid-columnHeader": {
            backgroundColor: "cMoon.main",
            color: "white",
          },
          ".MuiDataGrid-footerContainer": {
            display: "show",
          },
          ".MuiDataGrid-cell": {
            backgroundColor: "white",
          },
        }}
        disableColumnMenu={true}
      />
      <NewNews />
    </>
  );
};

const mapStateToProps = (state) => ({
  refresh: state.publishing.refresh,
  userLogin: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  openDrawer: (data, edit) =>
    dispatch({ type: ActionTypes.OPEN_NEW_NEWS, data, edit }),
  setNoRefresh: () => dispatch({ type: ActionTypes.NO_REFRESH }),
  setRefresh: () => dispatch({ type: ActionTypes.REFRESH }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DatagridSeries);
