import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Core from "./pages/Core";
import Create from "./pages/account/Create";
import Login from "./pages/account/Login";
import Forgot from "./pages/account/Forgot";
import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.min.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/account/login"
            element={
              <PublicRoute>
                <GoogleOAuthProvider clientId="852778491801-gj2t4fv30nr2fr61irn4gc13ckd5adsj.apps.googleusercontent.com">
                  <Login />
                </GoogleOAuthProvider>
              </PublicRoute>
            }
          />
          <Route
            path="/account/forgot"
            element={
              <PublicRoute>
                <Forgot />
              </PublicRoute>
            }
          />
          <Route
            path="/account/create"
            element={
              <PublicRoute>
                <GoogleOAuthProvider clientId="852778491801-gj2t4fv30nr2fr61irn4gc13ckd5adsj.apps.googleusercontent.com">
                  <Create />
                </GoogleOAuthProvider>
              </PublicRoute>
            }
          />
          <Route
            path="*"
            element={
              <PrivateRoute>
                <Core />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
};

export default App;
