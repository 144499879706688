import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Image from "mui-image";
import InputAdornment from "@mui/material/InputAdornment";
import Person from "@mui/icons-material/Person";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as ActionTypes from "../../redux/actions";
import { forgotPassword } from "../../controller/authController";
import { toast } from "react-toastify";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";

const Forgot = ({ onUserLogin }) => {
  const navigate = useNavigate();
  const [t] = useTranslation();

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.keyCode === 13) {
        sendForm();
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const sendPassword = async (username, password) => {
    try {
      await forgotPassword(username, password);
      toast.success("Please check your email");      
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          "Failed Operation"
      );
    }
  };

  const printFormError = (formik, key) => {
    if (formik.touched[key] && formik.errors[key]) {
      return <div>{formik.errors[key]}</div>;
    }
    return null;
  };

  const formik = useFormik({
    initialValues: {
      user: ""
    },
    validationSchema: Yup.object({
      user: Yup.string().required("Required")
    }),
    onSubmit: (values) => {
      sendPassword(values.user);
    },
  });

  const sendForm = () => {
    formik.submitForm();
  };

  return (
    <Container sx={{ backgroundColor: "secondary.main" }} maxWidth={false}>
      <Grid
        height="100vh"
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={6} sm={6} lg={3}>
          <Image
            src="/img/logo-menu.png"
            sx={{ mb: "10px", width: "300px !important" }}
          />
          <Card variant="outlined">
            <CardContent sx={{ textAlign: "center", mt: 3 }}>
              <Typography variant="h6" textAlign="center" mb="20px">
                Provide your account email
              </Typography>
              <TextField
                {...formik.getFieldProps("user")}
                sx={{ width: "90%", mb: "20px" }}
                id="user"
                error={printFormError(formik, "user") !== null ? true : false}
                helperText={printFormError(formik, "user")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Person />
                    </InputAdornment>
                  ),
                }}
                variant="filled"
              />

              <CardActions
                sx={{ justifyContent: "center", mb: "15px", mt: "10px" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => sendForm()}
                >
                  Forgot Password
                </Button>
              </CardActions>

              <Stack
                direction="row"
                alignItems="center"
                pt={1}
                justifyContent="center"
              >
                <Box sx={{ width: "50%" }}>
                  <Link to="/account/login">
                    <Typography variant="h7" textAlign="center">
                      Already have an account?
                    </Typography>
                  </Link>
                </Box>

                <Box sx={{ width: "50%" }}>
                  <Link to="/account/create">
                    <Typography variant="h7" textAlign="center">
                      Create an account
                    </Typography>
                  </Link>
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onUserLogin: (token) => dispatch({ type: ActionTypes.LOGIN_USER, token }),
});

export default connect(null, mapDispatchToProps)(Forgot);
