import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { checkValid } from "../controller/authController";

const PrivateRoute = ({ children, isLoggedIn }) => {
  const [validUser, setValidUser] = useState(null);

  const isUserSinged = async () => {
    try {
      if (isLoggedIn === true) {
        setValidUser(true);
      } else {
        const res = await checkValid();
        if (res.data.success === true) {
          setValidUser(true);
        } else {
          localStorage.removeItem("token");
          window.location.href = "/account/login";
          return null;
        }
      }
    } catch (error) {
      console.log(error);
      localStorage.removeItem("token");
      window.location.href = "/account/login";
      return null;
    }
  };

  useEffect(() => {
    //This only runs once.
    isUserSinged();
  }, []);

  if (validUser === null) {
    <Navigate
      to={{
        pathname: "/account/login",
        state: { from: "a" },
      }}
    />;
  } else {
    return children;
  }
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps, null)(PrivateRoute);
