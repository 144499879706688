import React from "react";
import { connect } from "react-redux";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Menu from "../components/drawer/Menu";
import Header from "../components/appbar/Header";
import Toolbar from "@mui/material/Toolbar";
import Footer from "../components/stack/Footer";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./Dashboard";
import PersonalInfo from "./PersonalInfo";
import PublishedAuthors from "./publishing/authors";
import PublishedSeries from "./publishing/series";
import PublishedBooks from "./publishing/books";
import PublishedNews from "./publishing/news";

const Main = ({ isMOD }) => {
  //toolbar used as an offset
  return (
    <Box sx={{ display: "flex" }}>
      <Header />
      <Menu />
      <Box sx={{ flexDirection: "column", width: "100%" }}>
        <Container
          sx={{
            backgroundColor: "secondary.main",
            height: "100%",
            minHeight: "100vh",
          }}
          maxWidth={false}
        >
          <Toolbar></Toolbar>
          <Routes>
            {isMOD === "yes" ? (
              <>
                <Route path="/published-books" element={<PublishedBooks />} />
                <Route
                  path="/published-authors"
                  element={<PublishedAuthors />}
                />
                <Route path="/published-series" element={<PublishedSeries />} />
                <Route path="/published-news" element={<PublishedNews />} />
              </>
            ) : null}
            <Route path="/personal-info" element={<PersonalInfo />} />
            <Route path="*" element={<Dashboard />} />
          </Routes>
        </Container>
        <Container sx={{ backgroundColor: "white" }} maxWidth={false}>
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  isMOD: state.auth.isMOD,
});
export default connect(mapStateToProps, null)(Main);

