import axios from "axios";

//https://api.moonquill.com

//http://10.0.2.2:5001/moonquill-core/us-central1/app
//http://127.0.0.1:5001/moonquill-core/us-central1/app"
//const url = "http://127.0.0.1:5001/moonquill-core/us-central1/app";
const url = "https://api.moonquill.com"
const instance = axios.create({
  baseURL: url,
});

// Function to renew token (replace this with your actual token renewal logic)
const renewToken = async () => {
  // Make a request to renew the token
  const res = await instance.post(
    "/v1/auth/renewTokenWeb",
    {
      nothing: "nothing",
    },
    { withCredentials: true, credentials: "include" }
  );
  //console.log(res); // Implement makeTokenRenewalRequest() function
  return "ups";
};

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  async function (error) {
    console.log(error);
    // Check if error response indicates token expiration
    if (error.response.status === 401) {
      try {
        // Attempt to renew the token
        const renewedToken = await renewToken();

        // If token renewal is successful, update the original request's headers with the new token
        error.config.headers.Authorization = `Bearer ${renewedToken}`;

        // Resend the original request with the updated token
        return axios.request(error.config);
      } catch (error) {
        console.log(error);
        // If token renewal fails, handle the error appropriately
        // For example, redirect to login page or display an error message
        return Promise.reject(error);
      }
    }

    // For other errors, simply reject the promise with the error
    return Promise.reject(error);
  }
);

export default instance;
