//i18 Language Handline file.
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

//locales files
import translationEN from "./en.json";
import translationES from "./es.json";


const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  }
  
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // default language
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
