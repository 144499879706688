import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";

const MenuBreadcrumbs = ({ mode }) => {
  const [t] = useTranslation();
  //Grab path
  const location = useLocation();
  //Split path
  let crumbs = location.pathname.split("/");
  //Clean path
  crumbs.splice(0, 1);

  //This is the / path leading to the dashboard. Since the split is empty we asign the dashboard name
  if (crumbs.length === 1 && crumbs[0] === "") {
    if (mode === "CLOUD") {
      crumbs[0] = "panel";
    } else {
      crumbs[0] = "dashboard";
    }
  }

  return (
    <Breadcrumbs sx={{ flexGrow: 1 }} aria-label="breadcrumb">
      {crumbs.map((name, key) => (
        <Typography key={key} color="text.primary">
          {t("routes." + name, name)}
        </Typography>
      ))}
    </Breadcrumbs>
  );
};

export default MenuBreadcrumbs;
