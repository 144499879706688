import { toast } from "react-toastify";

export const catchError = (error, functionName) => {
  if (error.response !== undefined) {
    console.log(error.response);
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/account/login";
    }
  }
  toast.error(functionName);
};
